import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'

export default function pushSubscription() {
  const localUrl = ref(themeConfig.isLocal)
  const baseUrl = ref(themeConfig.app.fileLink)
  const checkUrl = () => {
    if (baseUrl.value == localUrl.value){
      return false
    }
    else {
      return true
    }
  }
  return {
    localUrl,
    baseUrl,
    checkUrl,
  }
}
